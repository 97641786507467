import "goi_common/src/styles/globals.css";
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { AllRegionLayout } from "goi_common";

import { parlorRegion } from "../constants/parlorRegion";
import { GOI_WEB_URL, useResponsive } from "goi_common";

const regionLayout = parlorRegion.layout;
const regionColor = parlorRegion.regionColor;

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isBiggerThanMd } = useResponsive();
  return (
    <>
      <AppBar position="sticky" elevation={0} style={regionLayout.layoutStyle}>
        <Toolbar
          variant="dense"
          style={{
            width: "100%",
            maxWidth: "1280px",
            margin: "auto",
            position: "relative",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            href="/"
            sx={{
              background: "white",
            }}
          >
            <img src="/favicon.svg" width="30px" />
          </IconButton>
          <Stack
            direction="row"
            color="black"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            position="relative"
            zIndex={2}
          >
            <Stack direction="row">
              <Typography
                fontFamily="GmarketSans"
                fontWeight={700}
                fontSize="14px"
                variant="h6"
                color={regionLayout.layoutStyle.color}
              >
                {regionLayout.headTitle}
                <span className="orange650 head_24_b">.</span>
              </Typography>
            </Stack>
            {isBiggerThanMd && (
              <a href={GOI_WEB_URL + "/match/request/"}>
                <Button variant="outlined" sx={regionLayout.ctaButtonStyle}>
                  장례 총 비용 알아보기
                </Button>
              </a>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      <Container
        maxWidth="sm"
        style={{ padding: "0", background: regionColor.c100 }}
      >
        <AllRegionLayout eventPath="gangwon">{children}</AllRegionLayout>
      </Container>
    </>
  );
}
